<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ invalid, pristine }"
  >
    <b-modal
      id="modal-edit-contact"
      ref="modalEditContact"
      header-bg-variant="light-info"
      centered
      size="lg"
    >
      <template v-slot:modal-header>
        <h4 class="font-weight-bolder m-50">
          {{ $t('customer.editContact') }}
        </h4>
      </template>

      <template v-slot:modal-footer>
        <b-button
          variant="secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleCancelEditContact"
        >
          <!-- @click="resetContact" -->
          {{ $t('customer.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || pristine || !canAccess('customer.updateContact')"
          @click="handleEditContact"
        >
          {{ $t('customer.save') }}
        </b-button>
      </template>

      <b-form ref="form">
        <template #header>
          <div class="d-flex justify-content-between align-items-center w-100">
            <h5 class="m-0">
              {{ $t('customer.contactInfoText') }}
            </h5>
          </div>
        </template>
        <b-row>
          <!-- Last Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required"
            >
              <b-form-group
                :label="`${$t('customer.lastName')} (*)`"
                label-for="last-name"
              >
                <b-form-input
                  id="last-name"
                  v-model="contactDataToEdit.lastName"
                  :state="getValidationState(validationContext) === false ? false : null"
                  maxlength="30"
                  lazy-formatter
                  :formatter="trimInput"
                  :placeholder="$t('customer.placeholderLastName')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- FirstName -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required"
            >
              <b-form-group
                :label="`${$t('customer.firstName')} (*)`"
                label-for="first-name"
              >
                <b-form-input
                  id="first-name"
                  v-model="contactDataToEdit.firstName"
                  :state="getValidationState(validationContext) === false ? false : null"
                  maxlength="30"
                  lazy-formatter
                  :formatter="trimInput"
                  :placeholder="$t('customer.placeholderFirstName')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Date Of Birth -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="dob"
              rules="required"
            >
              <b-form-group
                :label="`${$t('customer.dob')} (*)`"
                label-for="dob"
              >
                <b-form-datepicker
                  v-model="contactDataToEdit.birthDay"
                  name="dob"
                  class="form-control"
                  :placeholder="$t('customer.placeholderDoB')"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :min="new Date(1920, 0, 1)"
                  :max="new Date()"
                  show-decade-nav
                  hide-header
                  locale="vi"
                  no-flip
                  @input="handleSelectBirthday(contactDataToEdit)"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- Type of Passenger -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Type of Passenger"
              rules="required"
            >
              <b-form-group
                :label="`${$t('customer.passengerType')}`"
                label-for="type-passenger"
              >
                <v-select
                  id="type-passenger"
                  v-model="contactDataToEdit.type"
                  label="label"
                  :options="typeOfPassengerOptions"
                  :reduce="(val) => val.value"
                  :disabled="true"
                >
                  <template #selected-option="data">
                    <span>
                      {{ $t(data.label) }}
                    </span>
                  </template>
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BModal,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
  watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { typeOfPassengerOptions } from '@/constants/selectOptions'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useCustomerHandle from '@customer/useCustomerHandle'

import { required, dateFormat } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BModal,
    BForm,
    BFormGroup,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const contactDataToEdit = ref(cloneDeep(props.contactData))

    const resetContactDataToEdit = () => {
      contactDataToEdit.value = cloneDeep(props.contactData)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetContactDataToEdit,
    )

    const {
      updateContact,
      refContactListTable,
      calculateAge,
    } = useCustomerHandle()

    watch(() => props.contactData, val => {
      contactDataToEdit.value = cloneDeep(val)
    }, { deep: true })

    // handle select type passenger
    function handleSelectBirthday(contact) {
      if (contact.birthDay) contactDataToEdit.value.type = calculateAge(contact.birthDay)
    }

    // Cancel Edit Contact
    function handleCancelEditContact() {
      this.$refs.modalEditContact.hide()
      resetForm()
    }

    // handle edit contact
    function handleEditContact() {
      const dataToUpdate = getDifference(contactDataToEdit.value, props.contactData)
      updateContact(
        props.contactData.owner.id,
        contactDataToEdit.value,
        dataToUpdate,
        () => {
          emit('refetch-data')
        },
      )
      this.$refs.modalEditContact.hide()
    }

    return {
      contactDataToEdit,
      refContactListTable,
      handleCancelEditContact,
      handleEditContact,
      required,
      dateFormat,

      refFormObserver,
      getValidationState,
      resetForm,

      handleSelectBirthday,
      typeOfPassengerOptions,

      trimInput,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#type-passenger ::v-deep .vs__actions {
  display: none;
}
</style>
