var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "border mt-1",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.contactInfoText')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', {
    staticClass: "justify-content-md-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-start pl-0",
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block ml-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPageContacts,
      callback: function callback($$v) {
        _vm.sizePerPageContacts = $$v;
      },
      expression: "sizePerPageContacts"
    }
  })], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "6",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('ContactAdd', {
    on: {
      "refetch-data": _vm.refetchDataContacts
    }
  })], 1), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-input-group', {
    staticClass: "w-100",
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('customer.placeholderSearchContact'),
      "value": _vm.contactsSearchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "debounce": "500",
      "trim": ""
    },
    model: {
      value: _vm.contactsSearchTextFilter,
      callback: function callback($$v) {
        _vm.contactsSearchTextFilter = $$v;
      },
      expression: "contactsSearchTextFilter"
    }
  })], 1)], 1)])], 1)], 1), _c('b-table', {
    ref: "refContactListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "46vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchContacts,
      "responsive": "",
      "fields": _vm.tableContacts,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('customer.noRecordFund'),
      "sort-desc": _vm.contactsIsSortDirDesc,
      "sort-by": _vm.contactsSortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.contactsIsSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.contactsIsSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.contactsSortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.contactsSortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableContacts, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("customer.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex w-100 h-100 align-items-center"
        }, [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "size": "40px",
            "src": data.item.photo,
            "text": _vm.avatarText("".concat(data.item.lastName, " ").concat(data.item.firstName)),
            "variant": "light-info"
          }
        }), _c('div', [_c('h5', {
          staticClass: "mb-0"
        }, [_c('b-button', {
          staticClass: "cursor-pointer font-weight-bold d-block text-nowrap text-info px-50",
          attrs: {
            "variant": "flat",
            "disabled": !_vm.canAccess('customer.updateContact')
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalEditContact(data.item, $event.target);
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")])], 1)])], 1)];
      }
    }, {
      key: "cell(birthDay)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex w-100 h-100 align-items-center"
        }, [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "GiftIcon",
            "size": "16"
          }
        })], 1), _c('h6', {
          staticClass: "mb-0"
        }, [data.item.birthDay ? _c('div', [_vm._v(" " + _vm._s(_vm.formatDateMonth(data.item.birthDay)) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noInfo')) + " ")])])], 1)];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex w-100 h-100 align-items-center"
        }, [_c('b-badge', {
          staticClass: "px-1 py-50",
          attrs: {
            "pill": "",
            "variant": _vm.resolveContactTypeVariant(data.item.type)
          }
        }, [_c('h6', {
          staticClass: "font-weight-bolder mb-0 text-light"
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveTypeOfPassengerOptions(data.item.type))) + " ")])])], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex flex-nowrap"
        }, [_c('b-button', {
          staticClass: "p-50 mr-50",
          attrs: {
            "variant": "flat-success",
            "disabled": !_vm.canAccess('customer.updateContact')
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalEditContact(data.item, $event.target);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "EditIcon",
            "size": "18",
            "stroke": "green",
            "stroke-width": "2"
          }
        })], 1), _c('b-button', {
          staticClass: "p-50 mr-50",
          attrs: {
            "variant": "flat-danger",
            "disabled": !_vm.canAccess('customer.deleteContact')
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(_vm.customerData.id, data.item);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "18",
            "stroke": "red",
            "stroke-width": "2"
          }
        })], 1)], 1)];
      }
    }], null, true)
  }), _c('ContactEdit', {
    attrs: {
      "contact-data": _vm.contactData
    },
    on: {
      "update:contactData": function updateContactData($event) {
        _vm.contactData = $event;
      },
      "update:contact-data": function updateContactData($event) {
        _vm.contactData = $event;
      },
      "refetch-data": _vm.refetchDataContacts
    }
  }), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.dataMetaContacts.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMetaContacts.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMetaContacts.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "\n            d-flex\n            align-items-center\n            justify-content-center\n          ",
    attrs: {
      "cols": "12",
      "sm": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalContacts,
      "per-page": _vm.sizePerPageContacts,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPageContacts,
      callback: function callback($$v) {
        _vm.currentPageContacts = $$v;
      },
      expression: "currentPageContacts"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCcwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('customer.back')))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }