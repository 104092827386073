var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "border mt-1",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.memberShip')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.memberCards,
      "responsive": "",
      "fields": ['airline', 'cardNumber'],
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('customer.noRecordFund'),
      "no-border-collapse": ""
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCcwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('customer.back')))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }