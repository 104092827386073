<template>
  <div><b-card
         header-tag="header"
         border-variant="info"
         header-bg-variant="light-info"
         header-class="py-1"
         class="border mt-1"
         no-body
         style="max-height: 65vh"
       >
         <template #header>
           <h6 class="m-0">
             {{ $t('customer.memberShip') }}
           </h6>
         </template>
         <b-table
           style="max-height: 50vh"
           :sticky-header="true"
           class="position-relative"
           :items="memberCards"
           responsive
           :fields="['airline', 'cardNumber']"
           primary-key="id"
           show-empty
           :empty-text="$t('customer.noRecordFund')"
           no-border-collapse
         />
       </b-card>
    <div class="d-flex justify-content-center">
      <!-- Back button -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-danger"
        @click="$emit('back')"
      >
        <feather-icon
          icon="RotateCcwIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('customer.back') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BTable, BButton, BCard,
  },

  props: {
    memberCards: {
      type: [Array, null],
      default: () => [],
    },
  },

  setup() {
    return {

    }
  },
}
</script>
