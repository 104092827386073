var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c(_vm.customerData === undefined ? 'div' : 'b-card', {
    tag: "component"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.customerData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.errFetchData_1')) + " ")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.errFetchData_2')) + " "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-customers-list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.customerList')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('customer.errFetchData_3')) + " ")], 1)]), _vm.customerData ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": _vm.tabIndex === 0
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UserIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('customer.information')))])];
      },
      proxy: true
    }], null, false, 1240899227)
  }, [_c('customers-edit-tab-account', {
    attrs: {
      "customer-data": _vm.customerData
    },
    on: {
      "back": _vm.onBack
    }
  })], 1), _c('b-tab', {
    attrs: {
      "active": _vm.tabIndex === 1,
      "disabled": !_vm.canAccess('customer.listContact')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('customer.contact')))])];
      },
      proxy: true
    }], null, false, 4176448260)
  }, [_c('customers-edit-tab-contact', {
    attrs: {
      "customer-data": _vm.customerData
    },
    on: {
      "back": _vm.onBack
    }
  })], 1), _c('b-tab', {
    attrs: {
      "active": _vm.tabIndex === 2,
      "disabled": !_vm.canAccess('customer.listContact')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('customer.memberShip')))])];
      },
      proxy: true
    }], null, false, 2768169014)
  }, [_c('customers-edit-tab-member-ship-card', {
    attrs: {
      "member-cards": _vm.customerData.memberCards
    },
    on: {
      "back": _vm.onBack
    }
  })], 1), false ? _c('b-tab', {
    attrs: {
      "active": _vm.tabIndex === 3,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "Share2Icon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('customer.historyBooking')))])];
      },
      proxy: true
    }], null, false, 2670891570)
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.noInfo')) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }