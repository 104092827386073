var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-end mr-50"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-add-contact",
      modifiers: {
        "modal-add-contact": true
      }
    }],
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('customer.createContact')
    },
    on: {
      "click": _vm.resetContact
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('customer.contact')) + " ")], 1)]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          ref: "modalAddContact",
          attrs: {
            "id": "modal-add-contact",
            "title": _vm.$t('customer.addContact'),
            "header-bg-variant": "light-info",
            "centered": "",
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-button', {
                attrs: {
                  "variant": "secondary",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
                },
                on: {
                  "click": _vm.handleCancelAddContact
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('b-button', {
                attrs: {
                  "variant": "primary",
                  "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
                  "disabled": invalid || !_vm.canAccess('customer.createContact')
                },
                on: {
                  "click": _vm.handleAddContact
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.save')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form', {
          ref: "form",
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center w-100"
              }, [_c('h5', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.contactInfoText')) + " ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Last Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.lastName'), " (*)"),
                  "label-for": "last-name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "last-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('customer.placeholderLastName')
                },
                model: {
                  value: _vm.contactToAdd.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contactToAdd, "lastName", $$v);
                  },
                  expression: "contactToAdd.lastName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "First Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.firstName'), " (*)"),
                  "label-for": "first-name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "first-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('customer.placeholderFirstName')
                },
                model: {
                  value: _vm.contactToAdd.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contactToAdd, "firstName", $$v);
                  },
                  expression: "contactToAdd.firstName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "dob",
            "rules": "required|dateFormat"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.dob'), " (*)"),
                  "label-for": "dob"
                }
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "name": "dob",
                  "placeholder": _vm.$t('customer.placeholderDoB'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(1920, 0, 1),
                  "max": new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "no-flip": ""
                },
                on: {
                  "input": function input($event) {
                    return _vm.handleSelectBirthday(_vm.contactToAdd);
                  }
                },
                model: {
                  value: _vm.contactToAdd.birthDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contactToAdd, "birthDay", $$v);
                  },
                  expression: "contactToAdd.birthDay"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Type of Passenger",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.passengerType')),
                  "label-for": "type-passenger"
                }
              }, [_c('v-select', {
                attrs: {
                  "id": "type-passenger",
                  "label": "label",
                  "options": _vm.typeOfPassengerOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "disabled": true
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }], null, true),
                model: {
                  value: _vm.contactToAdd.type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contactToAdd, "type", $$v);
                  },
                  expression: "contactToAdd.type"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }