<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <component
      :is="customerData === undefined ? 'div' : 'b-card'"
    >
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="customerData === undefined"
      >
        <h4 class="alert-heading">
          {{ $t('customer.errFetchData_1') }}
        </h4>
        <div class="alert-body">
          {{ $t('customer.errFetchData_2') }}
          <b-link
            class="alert-link"
            :to="{ name: 'apps-customers-list'}"
          >
            {{ $t('customer.customerList') }}
          </b-link>
          {{ $t('customer.errFetchData_3') }}
        </div>
      </b-alert>

      <b-tabs
        v-if="customerData"
        pills
      >
        <b-tab :active="tabIndex === 0">
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('customer.information') }}</span>
          </template>
          <customers-edit-tab-account
            :customer-data="customerData"
            @back="onBack"
          />
        </b-tab>
        <b-tab
          :active="tabIndex === 1"
          :disabled="!canAccess('customer.listContact')"
        >
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('customer.contact') }}</span>
          </template>
          <customers-edit-tab-contact
            :customer-data="customerData"
            @back="onBack"
          />
        </b-tab>
        <b-tab
          :active="tabIndex === 2"
          :disabled="!canAccess('customer.listContact')"
        >
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('customer.memberShip') }}</span>
          </template>
          <customers-edit-tab-member-ship-card
            :member-cards="customerData.memberCards"
            @back="onBack"
          />
        </b-tab>
        <b-tab
          v-if="false"
          :active="tabIndex === 3"
          disabled
        >
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('customer.historyBooking') }}</span>
          </template>
          {{ $t('customer.noInfo') }}
        </b-tab>
      </b-tabs>
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  ref, onUnmounted,
  // onMounted,
} from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

import { useRouter } from '@core/utils/utils'

import customerStoreModule from '@customer/customerStoreModule'

import CustomersEditTabAccount from './CustomersEditTabAccount.vue'
import CustomersEditTabContact from './CustomersEditTabContact.vue'
import CustomersEditTabMemberShipCard from './CustomersEditTabMemberShipCard.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    CustomersEditTabAccount,
    CustomersEditTabContact,
    CustomersEditTabMemberShipCard,
  },
  setup() {
    const { route } = useRouter()

    const tabs = ['#information', '#contacts', '#booking']
    const tabIndex = ref(tabs.findIndex(tab => tab === route.value.hash))
    const customerData = ref(null)
    const loading = ref(true)
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-customer/fetchCustomerById', { id: router.currentRoute.params.id })
      .then(response => {
        response.data.backupPhoneNumbers = response.data.backupPhoneNumbers ? response.data.backupPhoneNumbers?.split(' | ') : []
        customerData.value = response.data
      })
      .catch(error => {
        if (error) {
          customerData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    const onBack = () => {
      router.push(({ name: 'apps-customers-list' }))
    }

    return {
      tabIndex,
      customerData,
      loading,
      onBack,
    }
  },
}
</script>
