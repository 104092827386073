<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border mt-1"
      no-body
      style="max-height: 65vh"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('customer.contactInfoText') }}
        </h6>
      </template>

      <!-- Table Top -->
      <div class="mx-2 my-1">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >
          <!-- Per Page -->
          <b-col
            cols="6"
            md="auto"
            class="d-flex align-items-center justify-content-start pl-0"
          >
            <v-select
              v-model="sizePerPageContacts"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('customer.noOptions') }}
              </template>
            </v-select>
          </b-col>
          <!-- Button -->
          <b-col
            cols="6"
            md="auto"
            order-md="3"
            class="pr-0"
          >
            <!-- SECTION: MODAL ADD CONTACT -->
            <ContactAdd
              @refetch-data="refetchDataContacts"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <!-- Search Text -->
              <b-input-group
                size="md"
                class="w-100"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="contactsSearchTextFilter"
                  type="search"
                  :placeholder="$t('customer.placeholderSearchContact')"
                  :value="contactsSearchTextFilter"
                  :reduce="val => val.value"
                  debounce="500"
                  trim
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table Content -->
      <b-table
        ref="refContactListTable"
        style="max-height: 46vh"
        :sticky-header="true"
        class="position-relative"
        :items="fetchContacts"
        responsive
        :fields="tableContacts"
        primary-key="id"
        show-empty
        :empty-text="$t('customer.noRecordFund')"
        :sort-desc.sync="contactsIsSortDirDesc"
        :sort-by.sync="contactsSortBy"
        no-border-collapse
      >
        <template
          v-for="column in tableContacts"
          v-slot:[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark"
          >
            {{ $t(`customer.${data.label}`) }}
          </span>
        </template>

        <template #cell(information)="data">
          <div class="d-flex w-100 h-100 align-items-center">
            <b-avatar
              class="mr-1"
              size="40px"
              :src="data.item.photo"
              :text="
                avatarText(`${data.item.lastName} ${data.item.firstName}`)
              "
              variant="light-info"
            />
            <div>
              <h5 class="mb-0">
                <b-button
                  variant="flat"
                  class="cursor-pointer font-weight-bold d-block text-nowrap text-info px-50"
                  :disabled="!canAccess('customer.updateContact')"
                  @click="handleShowModalEditContact(data.item, $event.target)"
                >
                  {{ data.item.lastName }} {{ data.item.firstName }}
                </b-button>
              </h5>

            </div>
          </div>
        </template>

        <template #cell(birthDay)="data">
          <div class="d-flex w-100 h-100 align-items-center">
            <b-avatar
              class="mr-1"
              rounded
              variant="light-info"
              size="34"
            >
              <feather-icon
                icon="GiftIcon"
                size="16"
              />
            </b-avatar>
            <h6 class="mb-0">
              <div
                v-if="data.item.birthDay"
              >
                {{ formatDateMonth(data.item.birthDay) }}
              </div>
              <div
                v-else
              >
                {{ $t('noInfo') }}
              </div>
            </h6>
          </div>
        </template>

        <template #cell(type)="data">
          <div class="d-flex w-100 h-100 align-items-center">
            <b-badge
              pill
              :variant="resolveContactTypeVariant(data.item.type)"
              class="px-1 py-50"
            >
              <h6 class="font-weight-bolder mb-0 text-light">
                {{ $t(resolveTypeOfPassengerOptions(data.item.type)) }}
              </h6>
            </b-badge>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex flex-nowrap">
            <b-button
              variant="flat-success"
              class="p-50 mr-50"
              :disabled="!canAccess('customer.updateContact')"
              @click="handleShowModalEditContact(data.item, $event.target)"
            >
              <feather-icon
                icon="EditIcon"
                size="18"
                style="cursor: pointer"
                stroke="green"
                stroke-width="2"
              />
            </b-button>
            <b-button
              variant="flat-danger"
              class="p-50 mr-50"
              :disabled="!canAccess('customer.deleteContact')"
              @click="confirmDelete(customerData.id, data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="18"
                style="cursor: pointer"
                stroke="red"
                stroke-width="2"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <!-- SECTION: MODAL EDIT CONTACT -->
      <ContactEdit
        :contact-data.sync="contactData"
        @refetch-data="refetchDataContacts"
      />

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">{{ dataMetaContacts.from }} {{ $t('paginationText.to') }} {{ dataMetaContacts.to }} {{ $t('paginationText.outOf') }}
              {{ dataMetaContacts.of }} {{ $t('paginationText.items') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <b-pagination
              v-model="currentPageContacts"
              :total-rows="totalContacts"
              :per-page="sizePerPageContacts"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div class="d-flex justify-content-center">
      <!-- Back button -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-danger"
        @click="$emit('back')"
      >
        <feather-icon
          icon="RotateCcwIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('customer.back') }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BAvatar,
  BPagination,
  BFormInput,
  BInputGroup,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'

import { sizePerPageLgOptions, resolveTypeOfPassengerOptions } from '@/constants/selectOptions'
import store from '@/store'

import { avatarText, dateTime, formatDateMonth } from '@core/utils/filter'

import useCustomerHandle from '@customer/useCustomerHandle'
import customerStoreModule from '@customer/customerStoreModule'

import ContactEdit from './contacts/ContactEdit.vue'
import ContactAdd from './contacts/ContactAdd.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BAvatar,
    // BLink,
    BPagination,
    BFormInput,
    BInputGroup,
    BBadge,
    vSelect,

    ContactAdd,
    ContactEdit,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // STORE
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const contactData = ref({
      firstName: '',
      lastName: '',
      birthDay: '',
      type: '',
    })

    const {
      fetchContacts,

      tableContacts,
      sizePerPageContacts,
      currentPageContacts,
      totalContacts,
      dataMetaContacts,
      contactsSortBy, contactsIsSortDirDesc,
      refContactListTable,
      refetchDataContacts,

      // Extra Filters
      contactsSearchTextFilter,

      deleteContact,
      resolveContactTypeVariant,

    } = useCustomerHandle()

    async function handleShowModalEditContact(contact, event) {
      await new Promise(resolve => {
        contactData.value = contact
        resolve()
      })
      this.$root.$emit('bv::show::modal', 'modal-edit-contact', event)
    }

    // Delete Contact
    function confirmDelete(customerId, contact) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteContact(customerId, contact)
          }
        })
    }

    return {
      fetchContacts,
      confirmDelete,
      tableContacts,
      totalContacts,
      dataMetaContacts,
      contactsSortBy,
      contactsIsSortDirDesc,
      refContactListTable,
      refetchDataContacts,

      // Params
      sizePerPageContacts, // to API: size
      currentPageContacts, // to API: page

      // select Options
      sizePerPageLgOptions,

      // Extra Filters
      contactsSearchTextFilter,

      avatarText,
      dateTime,
      formatDateMonth,

      // Handle Contact
      deleteContact,
      resolveContactTypeVariant,

      // Options
      resolveTypeOfPassengerOptions,
      contactData,

      handleShowModalEditContact,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.media-body{
  height: 100%;
}
</style>
