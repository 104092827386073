<template>
  <div class="d-flex justify-content-end mr-50">
    <b-button
      v-b-modal.modal-add-contact
      variant="info"
      :disabled="!canAccess('customer.createContact')"
      @click="resetContact"
    >
      <span class="text-nowrap">
        <feather-icon
          class="cursor-pointer"
          icon="PlusSquareIcon"
          size="16"
        />
        {{ $t('customer.contact') }}
      </span>
    </b-button>

    <!-- Modal Add Contact -->
    <validation-observer
      ref="refFormObserver"
      #default="{invalid}"
    >
      <b-modal
        id="modal-add-contact"
        ref="modalAddContact"
        :title="$t('customer.addContact')"
        header-bg-variant="light-info"
        centered
        size="lg"
      >
        <template
          v-slot:modal-footer
        >
          <b-button
            variant="secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleCancelAddContact"
          >
            <!-- @click="resetContact" -->
            {{ $t('customer.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="invalid || !canAccess('customer.createContact')"
            @click="handleAddContact"
          >
            {{ $t('customer.save') }}
          </b-button>
        </template>

        <b-form ref="form">
          <template #header>
            <div class="d-flex justify-content-between align-items-center w-100">
              <h5 class="m-0">
                {{ $t('customer.contactInfoText') }}
              </h5>
            </div>
          </template>
          <b-row>
            <!-- Last Name -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('customer.lastName')} (*)`"
                  label-for="last-name"
                >
                  <b-form-input
                    id="last-name"
                    v-model="contactToAdd.lastName"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.placeholderLastName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- FirstName -->
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('customer.firstName')} (*)`"
                  label-for="first-name"
                >
                  <b-form-input
                    id="first-name"
                    v-model="contactToAdd.firstName"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.placeholderFirstName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>

            <!-- Date Of Birth -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="dob"
                rules="required|dateFormat"
              >
                <b-form-group
                  :label="`${$t('customer.dob')} (*)`"
                  label-for="dob"
                >
                  <b-form-datepicker
                    v-model="contactToAdd.birthDay"
                    name="dob"
                    class="form-control"
                    :placeholder="$t('customer.placeholderDoB')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="new Date(1920, 0, 1)"
                    :max="new Date()"
                    show-decade-nav
                    hide-header
                    locale="vi"
                    no-flip
                    @input="handleSelectBirthday(contactToAdd)"
                  />
                </b-form-group>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext) === false ? false : null"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <!-- Type of Passenger -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Type of Passenger"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('customer.passengerType')}`"
                  label-for="type-passenger"
                >
                  <v-select
                    id="type-passenger"
                    v-model="contactToAdd.type"
                    label="label"
                    :options="typeOfPassengerOptions"
                    :reduce="(val) => val.value"
                    :disabled="true"
                  >
                    <template #selected-option="data">
                      <span>
                        {{ $t(data.label) }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormDatepicker,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import { typeOfPassengerOptions } from '@/constants/selectOptions'
import { useRouter } from '@/@core/utils/utils'
// import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import { required, dateFormat } from '@validations'

import useCustomerHandle from '../../useCustomerHandle'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BModal,
    BFormDatepicker,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup(props, { emit }) {
    const contactData = ref({
      firstName: '',
      lastName: '',
      birthDay: '',
      type: '',
    })
    const contactToAdd = ref(cloneDeep(contactData))
    const resetContact = () => {
      contactToAdd.value = cloneDeep(contactData)
    }
    const { route } = useRouter()
    const { refFormObserver, getValidationState } = formValidation(
      resetContact,
    )
    const {
      calculateAge,
      addContact,
    } = useCustomerHandle()

    // Select type passenger
    function handleSelectBirthday(contact) {
      if (contact.birthDay) contactToAdd.value.type = calculateAge(contact.birthDay)
    }

    // Add Contact
    function handleAddContact() {
      const { id } = route.value.params
      addContact(id, contactToAdd.value, () => {
        emit('refetch-data')
      })
      this.$refs.modalAddContact.hide()
    }

    // Cancel Add Contact
    function handleCancelAddContact() {
      this.$refs.modalAddContact.hide()
    }

    return {
      // Data and ref
      contactToAdd,
      refFormObserver,
      getValidationState,

      // Validations
      required,
      dateFormat,

      // Methods
      resetContact,
      handleAddContact,
      handleCancelAddContact,

      trimInput,

      // Options
      typeOfPassengerOptions,
      handleSelectBirthday,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#type-passenger ::v-deep .vs__actions{
display: none;
}
</style>
